<template>
  <div class="analyse-chart">
    <div class="chart-table">
      <div class="chart-title">
        <span class="chart-name"><i></i>{{ showLang('com.stat.light.rate') }}</span>
        <span class="chart-more" @click="routeJump(1)">{{ showLang('com.op.more') }}<img :src="img.light.road.single.header.toRight" alt=""/></span>
      </div>
      <div class="chart-show">
        <chartsLine :option="chartsLineData" id="lightingRate" :key="timer"></chartsLine>
      </div>
    </div>
    <div class="chart-table">
      <div class="chart-title">
        <span class="chart-name"><i></i>{{ showLang('com.stat.energy') }}</span>
        <span class="chart-more" @click="routeJump(2)">{{ showLang('com.op.more') }}<img :src="img.light.road.single.header.toRight" alt=""/></span>
      </div>
      <div class="chart-show">
        <chartsLine :option="chartsLineData2" id="powerSavingRate" :key="timer"></chartsLine>
      </div>
    </div>
    <div class="chart-table">
      <div class="chart-title">
        <span class="chart-name"><i></i>{{ showLang('com.stat.alarm') }}</span>
        <span class="chart-more" @click="routeJump(3)">{{ showLang('com.op.more') }}<img :src="img.light.road.single.header.toRight" alt=""/></span>
      </div>
      <div class="chart-type">
        <div class="chart-type-list" v-for="(item, idx) in alarmLevels" :key="idx">
          <span class="chart-type-name">{{ item.key }}</span>
          <span class="chart-type-num">{{ item.value }}</span>
        </div>
      </div>
      <div class="chart-show" style="border-right:none;height: 200px;">
        <div class="chart-type-left">
          <div class="chart-type-list" v-for="(el, index) in chartsPieData.series.slice(0, 3)" :key="index">
            <span class="chart-type22" style="padding: 15px 15px 5px 0;"
              >{{ el.name }} <span class="chart-num">{{ el.value }}</span>
              <i
                class="chart-type-color"
                :style="{
                  backgroundColor: `${chartsPieData.color[index]}`
                }"
              ></i
            ></span>
          </div>
        </div>
        <div class="chart-type-chart">
          <div class="chart-show-core">
            <img :src="img.home.page.policeBox" alt="" />
            <span>{{ analysisBoxTotal }}</span>
          </div>
          <chartsPie :option="chartsPieData" id="chartsPieData" :key="timer" class="chart-show-box"></chartsPie>
        </div>
        <div class="chart-type-left ">
          <div class="chart-type-list con-right " v-for="(el, index) in chartsPieData.series.slice(3, 6)" :key="index">
            <span class="chart-type22"  style="padding: 15px 0 5px 15px;"
              ><i class="chart-type-color" :style="{ backgroundColor: `${chartsPieData.color[index + 3]}` }"> </i><span class="chart-num"> {{ el.value }}</span> {{ el.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <Modal v-model="showPopup" :title="showPopupName" width="1250">
      <template v-if="showPopupIndex == 1">
        <lightRatePage />
      </template>
      <template v-if="showPopupIndex == 2">
        <energyPage />
      </template>
      <template v-if="showPopupIndex == 3">
        <policePage />
      </template>
      <div slot="footer">
        <Button type="info" @click="delPopup">{{ showLang('com.op.close') }}</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { setCookie, getCookie } from '@/plugins/cookie'
import policePage from './popup/police'
import lightRatePage from './popup/lightRate'
import energyPage from './popup/energy'
Vue.prototype.$cookieStore = { setCookie, getCookie }
export default {
  name: 'DataAnalysis',
  components: {
    policePage,
    lightRatePage,
    energyPage
  },
  props: {
    tabNameType: {
      type: String,
      default: 'power'
    }
  },
  data() {
    return {
      lightOnRate: 0,
      alarmCount: 0,
      menuIndex: 0,
      showPopup: false,
      showPopupIndex: 0,
      showPopupName: '',
      timer: '',
      //亮灯率
      chartsLineData: {
        color: ['#02CBB1'],
        xColor: '#B5C1CC',
        yColor: '#99A8B6',
        title: '',
        x: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun5'],
        unit: '%',
        series: [
          {
            name: '亮灯率',
            data: [0, 0, 0, 0, 0, 0, 0]
          }
        ],
        setSeries: {
          smooth: true, //平滑折线图
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#02CBB1' // 0% 处的颜色
                },
                {
                  offset: 0.5,
                  color: 'rgba(2,205,177,0.3)' // 100% 处的颜色
                },
                {
                  offset: 0.9,
                  color: 'rgba(2,205,177,0.1)' // 100% 处的颜色
                }
              ],
              global: false // 缺省为 false
            }
          } //下阴影
        }
      },
      //节电率
      chartsLineData2: {
        color: ['#02CB9E', '#A468FE', '#FC58B1', '#5A78FC', '#49B0FD'],
        title: '',
        xColor: '#B5C1CC',
        yColor: '#99A8B6',
        x: [],
        unit: '',
        series: [
          {
            name: '当日能耗(KWh)',
            data: []
          },
          {
            name: '节电率(%)',
            data: []
          },
          {
            name: '额定功率(KW)',
            data: []
          },
          {
            name: '当日费用(元)',
            data: []
          },
          {
            name: '节省电费(元)',
            data: []
          }
        ],
        setSeries: {
          smooth: true //平滑折线图
        }
      },
      isTimeShow: true,
      chartsPieData: {
        color: ['#38B8D6', '#93ACFF', '#C69AFF', '#F48BA7', '#FCD788', '#52CD8B'],
        series: [
          { value: 9, name: '-' },
          { value: 6, name: '-' },
          { value: 5, name: '-' },
          { value: 4, name: '-' },
          { value: 2, name: '-' },
          { value: 3, name: '-' }
        ],
        content: {
          tooltip: {
            trigger: 'item'
          }
        },
        setSeries: {
          label: {
            show: false,
            position: 'center'
          },
          radius: ['75%', '90%'],
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 4
          }
        }
      },
      policeTypeData: [],
      policeLevels: [0, 0, 0],
      alarmLevels: [],
      analysisBoxTotal: 0,
      timeout: null
    }
  },
  computed: {
    ...mapState('cmd', ['cmds', 'cmdResultRefresh', 'windowOnResize', 'lightCmds']),
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('auth', ['stationTabName'])
  },
  watch: {
    stationTabName() {
      this.debounceInitData()
    },
    selectedNode() {
      this.debounceInitData()
    }
  },
  mounted: function() {
    this.widthChange()
    this.debounceInitData()
    this.chartsLineData.series[0].name = this.showLang('com.stat.light.rate')
    this.chartsLineData2.series[0].name = this.showLang('com.stat.day.energy')
    this.chartsLineData2.series[1].name = this.showLang('com.stat.saving.rate')
    this.chartsLineData2.series[2].name = this.showLang('energy.before.energyPower') + '(KW)'
    this.chartsLineData2.series[3].name = this.showLang('com.stat.day.fee')
    this.chartsLineData2.series[4].name = this.showLang('com.stat.save.charge')
  },
  methods: {
    delPopup() {
      this.showPopup = false
    },
    debounceInitData: function() {
      // this.widthChange()
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.loadChert, 500)
    },
    routeJump(index) {
      this.showPopup = true
      this.showPopupIndex = index
      switch (index) {
        case 1:
          this.showPopupName = this.showLang('com.stat.light.rate')
          break
        case 2:
          this.showPopupName = this.showLang('com.stat.energy')
          break
        case 3:
          this.showPopupName = this.showLang('com.stat.alarm')
          break
      }
    },
    loadChert() {
      this.powerSaving() //能耗统计
      this.lightingRate() //亮灯率
      this.policeType() //报警统计
    },
    policeType: async function() {
      let res = await this.$axios.get(`//${this.domains.trans}/station/alarm/type`)
      if (res.code !== 0) {
        // this.$Message.error(res.data);
        return
      }
      this.policeTypeData = res.data
      this.policeAjax()
    },
    policeAjax: function() {
      let groupId = 0,
        stationId = 0
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id
      } else {
        groupId = this.selectedNode.id
      }
      this.$axios.post(`//${this.domains.trans}/station/alarm/AnalyseStation`, { groupId, stationId }).then(res => {
        let types = []
        res.data.types.map(p => {
          types.push({ name: p.key, value: p.value })
        })
        this.chartsPieData.series = this.typeAdd(types)
        // console.log('this.typeAdd(types)')
        // console.log(this.typeAdd(types))
        this.analysisBoxTotal = res.data.count
        this.$set(this, 'alarmLevels', res.data.levels)
        this.widthChange()
      })
    },
    typeAdd(data) {
      if (data.length > 6) {
        data[5].name = this.showLang('com.op.other')
        data.forEach((el, index) => {
          if (index > 5) {
            data[5].value = el.value * 1 + data[5].value * 1
          }
        })
      }
      return data.slice(0, 6)
    },
    lightingRate: async function() {
      let filter = {
        groupId: 0,
        stationId: 0,
        // "type": this.stationTabName,
        start: '',
        end: ''
      }
      let now = new Date()
      filter.end = now.format('yyyy-MM-dd')
      now.setDate(now.getDate() - 6)
      filter.start = now.format('yyyy-MM-dd')
      switch (this.selectedNode.type) {
        case 'root':
          filter.groupId = 0
          filter.stationId = 0
          break
        case 'group':
          filter.groupId = this.selectedNode.id
          filter.stationId = 0
          break
        default:
          filter.groupId = 0
          filter.stationId = this.selectedNode.id
          break
      }
      let res = await this.$axios.post(`//${this.domains.trans}/station/analyse/LightOnTrend`, filter)
      this.chartsLineData.x = []
      this.chartsLineData.series[0].data = []
      if (res.data.length > 0) {
        res.data.forEach(el => {
          el.value = (el.value * 1).toFixed(2)
          this.chartsLineData.x.push(el.time)
          this.chartsLineData.series[0].data.push(el.value)
        })
      }

      this.widthChange()
    },
    powerSaving: async function() {
      let now = new Date()
      let end = now.format('yyyy-MM-dd')
      now.setDate(now.getDate() - 6)
      let start = now.format('yyyy-MM-dd')
      let filter = { groupId: 0, stationId: 0, type: this.stationTabName, start, end }
      switch (this.selectedNode.type) {
        case 'root':
          filter.groupId = 0
          filter.stationId = 0
          break
        case 'group':
          filter.groupId = this.selectedNode.id
          filter.stationId = 0
          break
        case 'Station':
          filter.groupId = 0
          filter.stationId = this.selectedDevices.map(p => p.id)[0]
          break
      }
      let res = await this.$axios.post(`//${this.domains.trans}/station/analyse/EnergyMore`, filter)
      if (res.code != 0) {
        return false
      }
      this.chartsLineData2.x = []
      this.chartsLineData2.series[0].data = []
      this.chartsLineData2.series[1].data = []
      this.chartsLineData2.series[2].data = []
      this.chartsLineData2.series[3].data = []
      this.chartsLineData2.series[4].data = []
      res.data.forEach(el => {
        el.val = (el.val * 1).toFixed(2)
        this.chartsLineData2.x.push(el.date)
        this.chartsLineData2.series[0].data.push(this.img.val(el.energy, 2))
        this.chartsLineData2.series[1].data.push(this.img.val(el.rate, 2))
        this.chartsLineData2.series[2].data.push(this.img.val(el.pr, 2))
        this.chartsLineData2.series[3].data.push(this.img.val(el.money, 2))
        this.chartsLineData2.series[4].data.push(this.img.val(el.save, 2))
      })

      this.widthChange()
    },
    widthChange() {
      this.timer = new Date().getTime()
    }
  }
}
</script>
<style scoped>
.chart-show-core {
  position: absolute;
  width: 78px;
  height: 78px;
  background: #ffffff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  left: 50%;
  margin-left: -39px;
  z-index: 500;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: -40px;
  top: 50%;
}
.chart-show-core img {
  width: 30px;
  height: 30px;
  margin: 10px auto 0;
}
.chart-show-core span {
  font-size: 20px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #324252;
}
.chart-show .chart-show-box {
  height: 180px;
  width: 180px;
  margin: 0 auto;
  border: 2px solid #dae8f5;
  border-radius: 50%;
  box-sizing: border-box;
}
.chart-type-name {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8493a1;
}
.chart-type-num {
  font-size: 16px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #324252;
}
.chart-type {
  display: flex;
  justify-content: space-between;
  padding: 15px 32px 5px;
}
.chart-type-list {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.chart-time {
  height: 22px;
}
.chart-time span {
  width: 56px;
  height: 22px;
  border: 1px solid #c0c5cb;
  box-sizing: border-box;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737d;
  display: inline-block;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.chart-time span.active {
  color: #3880fc;
  border: 1px solid #3880fc;
}
.analyse-chart {
  display: flex;
  height: 320px;
  margin-bottom: 30px;
}
.analyse-chart.hideShita {
  max-height: 320px;
  overflow: hidden;
  transition: max-height 0.5s;
}
.analyse-chart.element {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
  margin-bottom: -1px;
}
.chart-table {
  width: 33.33%;
}
.chart-title {
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
  padding: 20px 20px 0;
}
.chart-name {
  display: flex;
  justify-self: start;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #324252;
}
.chart-name i {
  display: inline-block;
  width: 7px;
  height: 7px;
  background: #324252;
  border-radius: 4px;
  margin-right: 5px;
  margin-top: 8px;
}
.chart-more {
  width: 54px;
  height: 24px;
  border: 1px solid #3880fc;
  border-radius: 3px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #3880fc;
  text-align: center;
  cursor: pointer;
}
.chart-more img {
  margin-left: 2px;
}
.chart-show {
  width: 100%;
  height: 235px;
  flex: auto;
  display: flex;
  border-right: 1px dashed #dee5ed;
  padding: 0 15px;
  box-sizing: border-box;
  position: relative;
  justify-content: center;
}
    /* justify-content: flex-end; */
.chart-show .chart-show-police {
  position: absolute;
  margin-top: -44px;
  top: 50%;
  left: 50%;
  margin-left: -43px;
  z-index: 500;
}

.data-analyse-box {
  width: 100%;
  padding: 30px 64px 0 30px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin: 16px 0;
}
.analyse-title {
  height: 52px;
  flex: none;
  border-bottom: solid 1px #dee5ed;
  display: flex;
  justify-content: space-between;
}
.analyse-title .title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021629;
}
.analyse-title img.rotateImg {
  transform: rotate(180deg);
  transition: transform 0.5s;
}
.analyse-title img.rotateImg2 {
  transform: rotate(0deg);
  transition: transform 0.5s;
}
.triangle-down {
  width: 0;
  height: 0;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-top: 8px solid #3880fc;
}
.chart-type-left {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.chart-type-list {
  height: 23%;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  text-align: right;
  color: #8493a1;
  line-height: 30px;
  margin: 5px 0;
}
.chart-type-list.con-right {
  text-align: left;
}
.chart-type-color {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
    margin: 0px 5px 2px;
}
.chart-num {
  font-size: 16px;
  color: #028cf2;
}
.chart-type-chart {
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  border: 1px solid #09265c;
  border-radius: 50%;
  margin: 0 10px;
  box-sizing: border-box;
}
</style>
