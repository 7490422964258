<template>
  <Modal v-model="showModal" :mask-closable="false" width="1020px">
    <div slot="header">{{showLang('com.edit.box.schedule')}}</div>
    <div class="total-container">
      <div class="float-container">
        <div>
          <Form :model="form" :label-width="40" inline style="margin-left: 20px">
            <FormItem :label="showLang('com.tab.title')">
              <Input ref="name" type="text" v-model="form.name" maxlength="20" style="width: 380px"
                :placeholder="showLang('save.name')"></Input>
            </FormItem>
            <FormItem :label="showLang('com.state.default')" :label-width="70">
              <i-switch v-model="form.isDefault" size="large" true-color="#13ce66" false-color="#ff4949">
                <span slot="open">{{showLang('com.op.yes')}}</span>
                <span slot="close">{{showLang('com.op.deny')}}</span>
              </i-switch>
            </FormItem>
            <FormItem :label-width="0">
              <Button type="primary" v-if="form.type !== '0'"
                @click="editDayAction(1)">{{showLang('timetable.batch.modify')}}</Button>
            </FormItem>
          </Form>
        </div>
        <div v-if="form.type != '0'">
          <Button v-for="(item, idx) in months" :key="idx" :type="midx == idx ? 'primary':'default'"
            style="margin: 3px 4px; width: 71px" @click="switchMonth(idx)">{{showLang(item.lang)}}</Button>
        </div>
        <!-- <div style="">
        <Button v-for="(item, idx) in weeks" :key="idx" :type="idx > 4 ? 'warning' : 'default'" :ghost="idx > 4" :style="{margin: '3px 5px', width: '125px'}">{{item}}</Button>
      </div> -->
        <div class="edit-container">
          <div class="time-container" v-if="form.type != '0'">
            <!-- <div :style="{width: `${(week - 1) * 135}px`, height: 0}"></div> -->
            <div class="time-item" v-for="m in 31" :key="m" @click="editDayAction(0, m, midx)">
              <div class="time-title">{{m}}</div>
              <!-- {{midx + 1}}-{{m}} -->
              <p v-for="n in 4" :key="n">{{n}}、{{getAction(form.type, midx+1, m, n)}}</p>
            </div>
            <!-- <div class="time-item" v-if="midx == 1 && days < 29" @click="editDayAction(0, 29, midx)">
            <div class="time-title">29</div>
            <p v-for="n in 4" :key="n">{{n}}、{{getAction(form.type, midx+1, 29, n)}}</p>
          </div> -->
          </div>
        </div>
      </div>
      <ModalDayAction v-model="showDayModal" :item="dayEditItem" @dayActionSaved="dayActionSaved" />
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="saveTimeTable">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalDayAction from './ModalDayAction'
export default {
  name: 'ModalTimeTable',
  components: {
    ModalDayAction,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showDayModal: false,
      gpsEdit: true,
      months: [
        { name: '一月', lang: 'com.date.Jan' },
        { name: '二月', lang: 'com.date.Feb' },
        { name: '三月', lang: 'com.date.Mar' },
        { name: '四月', lang: 'com.date.Apr' },
        { name: '五月', lang: 'com.date.May' },
        { name: '六月', lang: 'com.date.Jun' },
        { name: '七月', lang: 'com.date.Jul' },
        { name: '八月', lang: 'com.date.Aug' },
        { name: '九月', lang: 'com.date.Sep' },
        { name: '十月', lang: 'com.date.Oct' },
        { name: '十一月', lang: 'com.date.Nov' },
        { name: '十二月', lang: 'com.date.Dec' }],
      weeks: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日',],
      midx: 0,
      days: 31,
      week: 0,
      showModal: this.value,
      isAdd: false,
      index: -1,
      loading: false,
      form: {
        id: 0,
        name: '',
        isDefault: false,
        content: {
          1: {
            1: { t1: '00:00', a1: 2, t2: '00:00', a2: 2, t3: '00:00', a3: 2, t4: '00:00', a4: 2, t5: '00:00', a5: 2, t6: '00:00', a6: 2 },
            2: { t1: '00:00', a1: 2, t2: '00:00', a2: 2, t3: '00:00', a3: 2, t4: '00:00', a4: 2, t5: '00:00', a5: 2, t6: '00:00', a6: 2 },
            //...
          },
          2: {},
          //...
        },
      },
      dayEditItem: {},
      powerTableActions: {
        0: '关闭',
        1: '开启',
        2: '无效'
      }
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (this.showModal) {
        this.isAdd = this.item.isAdd;
        this.index = this.item.index;
        if (this.isAdd) {
          this.form.id = 0;
          this.form.name = '';
          this.form.isDefault = false;
          this.form.content = {};
          for (let i = 1; i <= 12; i++) {
            this.form.content[i] = {};
            for (let j = 1; j <= 31; j++) {
              this.form.content[i][j] = {
                a1: 2,
                t1: '00:00',
                a2: 2,
                t2: '00:00',
                a3: 2,
                t3: '00:00',
                a4: 2,
                t4: '00:00',
              }
            }
          }
        } else {
          this.form.id = this.item.data.id;
          this.form.name = this.item.data.name;
          this.form.isDefault = this.item.data.isDefault;
          this.getTableContent();
        }
        let now = new Date();
        let month = now.getMonth();
        this.switchMonth(month);
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  mounted: function () {
    // this.getTables();
    this.weeks = [this.showLang('com.date.mon'), this.showLang('com.date.tues'), this.showLang('com.date.wed'), this.showLang('com.date.thurs'), this.showLang('com.date.fri'), this.showLang('com.date.sat'), this.showLang('com.date.sunday')]
    this.powerTableActions = {
      0: this.showLang('com.op.close'),// '关闭',
      1: this.showLang('com.state.open'),// '开启',
      2: this.showLang('com.state.invalid'),// '无效'
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('common', ['mapCenter']),
  },
  methods: {
    saveTimeTable: async function () {
      if (!this.form.name || this.form.name.length > 20) {
        this.$Message.warning(this.showLang('com.save.err.len.name', 20));
        return;
      }
      this.loading = true;
      for (let i = 1; i <= 12; i++) {
        let monthObj = this.form.content[i];
        if (!monthObj) {
          this.form.content[i] = {};
          monthObj = this.form.content[i];
        }
        for (let j = 1; j <= 31; j++) {
          let dayObj = monthObj[j];
          if (!dayObj) {
            monthObj[j] = {
              a1: 2,
              t1: '00:00',
              a2: 2,
              t2: '00:00',
              a3: 2,
              t3: '00:00',
              a4: 2,
              t4: '00:00',
            };
            dayObj = monthObj[j];
          }
          dayObj.a1 = parseInt(dayObj.a1);
          dayObj.a2 = parseInt(dayObj.a2);
          dayObj.a3 = parseInt(dayObj.a3);
          dayObj.a4 = parseInt(dayObj.a4);
        }
      }
      this.$axios.post(`//${this.domains.trans}/station/plan/SaveCommonYearDetail`, this.form).then(res => {
        this.loading = false;
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.showModal = false;
          this.$emit('saved');
        }
      });
    },
    dayActionSaved: function (params) {
      // // 0、单日年表，1、批量年表，2、单日周表
      if (params.type == 0) {
        for (let i = 1; i <= 4; i++) {
          this.form.content[params.month + 1][params.day][`t${i}`] = params.action[`t${i}`];
          this.form.content[params.month + 1][params.day][`a${i}`] = params.action[`a${i}`];
        }
      }
      else if (params.type == 1) {
        let start = new Date(params.action.start);
        let end = new Date(params.action.end);
        let startMonth = start.getMonth(), startDay = start.getDate();
        let endMonth = end.getMonth(), endDay = end.getDate();
        // let weekEnables = params.action.weekEnables;
        let year = new Date().getFullYear();
        let i = startMonth, j = startDay;
        while (params.type == 1) {
          let action = this.form.content[i + 1][j];
          if (params.gps) {
            let day = new Date(`${year}-${i + 1}-${j}`);
            if (!day) {
              // debugger;
              j++;
              if (i * 31 + j > endMonth * 31 + endDay) break;
              if (j > 31) {
                j = 1;
                i++;
              }
              continue
            }
            let riseAndSet = day.sunRiseAndSet(params.gpsForm.lat, params.gpsForm.lng);
            for (let k = 1; k <= 4; k++) {
              if (!params.action[`c${k}`]) continue;
              if (!this.isCycle(params, i, j)) continue;
              let t;
              if (params.action[`r${k}`] == '1') {//sun rise
                t = riseAndSet.rise;
              } else {//sun set
                t = riseAndSet.set;
              }
              let bb = params.action[`b${k}`];
              let d = parseInt(params.action[`d${k}`]);
              if (bb == '2') {//提前
                t = this.delayTime(t, -1 * d);
              } else if (bb == '3') {//延后
                t = this.delayTime(t, d);
              }
              action[`t${k}`] = t;
              action[`a${k}`] = params.action[`a${k}`];
            }
          } else {
            for (let k = 1; k <= 4; k++) {
              if (!params.action[`c${k}`]) continue;
              if (!this.isCycle(params, i, j)) continue;
              action[`t${k}`] = params.action[`t${k}`];
              action[`a${k}`] = params.action[`a${k}`];
            }
          }
          j++;
          if (i * 31 + j > endMonth * 31 + endDay) break;
          if (j > 31) {
            j = 1;
            i++;
          }
        }
      }
    },
    delayTime: function (time, delay) {//time: 05:34, delay: 5 | -5
      let ts = time.split(':');
      let h = parseInt(ts[0]), m = parseInt(ts[1]);
      let t = h * 60 + m + delay;
      let h1 = parseInt(t / 60), m1 = t - h1 * 60;
      let hs = `${h1}`.padStart(2, '0');
      let ms = `${m1}`.padStart(2, '0');
      return `${hs}:${ms}`;
    },
    editDayAction: function (type, day, month) {
      // type: 0、单日年表，1、批量年表，2、单日周表
      let action;
      if (type == 0) {
        action = this.form.content[this.midx + 1][day];
      } else {
        action = {
          start: '01-01',
          end: '12-31',
          weekEnables: [this.showLang('com.date.mon'), this.showLang('com.date.tues'), this.showLang('com.date.wed'), this.showLang('com.date.thurs'), this.showLang('com.date.fri'), this.showLang('com.date.sat'), this.showLang('com.date.sunday')],
          a1: 0,
          t1: '07:00',
          r1: '1',
          b1: '1',
          d1: '5',
          c1: true,
          a2: 1,
          t2: '19:00',
          r2: '2',
          b2: '1',
          d2: '5',
          c2: true,
          a3: 2,
          t3: '00:00',
          r3: '1',
          b3: '1',
          d3: '5',
          c3: false,
          a4: 2,
          t4: '00:00',
          r4: '1',
          b4: '1',
          d4: '5',
          c4: false,
        };
      }
      this.dayEditItem = { type: type, day: day, month: month, action: action };
      this.showDayModal = true;
    },
    isCycle(data, i, j) {
      let cycle=false;
      if (data.cycle.length == 0) return false;
      let date = new Date(data.yearValue, i, j);
      let dayOfWeekIndex = date.getDay();
      data.cycle.forEach(el => {
        if(el==dayOfWeekIndex){
            cycle=true;
        }
      });
      return cycle;
    },
    getAction: function (type, month, day, idx) {
      let m = this.form.content[month];
      if (!m) return '00:00 ' + this.showLang('com.state.invalid');
      let d = m[day];
      if (!d) return '00:00 ' + this.showLang('com.state.invalid');
      return `${d['t' + idx]} ${this.powerTableActions[d['a' + idx]]}`;
    },
    switchMonth: function (idx) {
      this.midx = idx;
      let now = new Date();
      let year = now.getFullYear();
      let firstDate = new Date(`${year}-${idx + 1}-1`);
      this.week = firstDate.getDay();
      if (this.week == 0) {
        this.week = 7;
      }
      firstDate.setMonth(firstDate.getMonth() + 1);
      firstDate.setDate(firstDate.getDate() - 1);
      this.days = firstDate.getDate();
      // this.getTableContent();
    },
    getTableContent: async function () {
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryCommonYearDetail`, { id: this.form.id }).then(res => {
        this.loading = false;
        if (res.code !== 0) {
          // this.$Message.error(res.data);
          // this.hideLoading();
          return;
        }
        this.form.name = res.data.name;
        for (let i = 1; i <= 12; i++) {
          let monthObj = res.data.content[i];
          if (!monthObj) {
            res.data.content[i] = {};
            monthObj = res.data.content[i];
          }
          for (let j = 1; j <= 31; j++) {
            let dayObj = monthObj[j];
            if (!dayObj) {
              monthObj[j] = {
                a1: 2,
                t1: '00:00',
                a2: 2,
                t2: '00:00',
                a3: 2,
                t3: '00:00',
                a4: 2,
                t4: '00:00',
              };
              dayObj = monthObj[j];
            }
          }
        }
        this.$set(this.form, 'content', res.data.content);
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.total-container {
  height: 600px;
  display: flex;
}
.edit-container {
  height: calc(100% - 140px);
  /* border: solid 1px red; */
  overflow-y: auto;
  width: 970px;
}
.float-container {
  width: 10px;
  flex: auto;
  border: solid 1px #dcdee2;
  margin-left: 5px;
  border-radius: 6px;
  padding-top: 5px;
}
.time-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.time-item {
  border: solid 1px #4767aa;
  border-radius: 3px;
  width: 125px;
  height: 180px;
  background: linear-gradient(to top, #cccccc, #ffffff);
  font-family: 微软雅黑, 宋体;
  margin: 5px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
.time-title {
  font-size: 22px;
  color: #4767aa;
  font-style: italic;
  font-weight: bold;
}
</style>