var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-container"},[_c('vxe-table',{key:_vm.dataRefresh,ref:"chkTable",attrs:{"border":"","resizable":"","show-overflow":"","show-header-overflow":"","keep-source":"","data":_vm.showList,"height":_vm.tabHeight,"header-row-class-name":_vm.setHeaderRowClass,"align":"center","header-row-style":{textAlign: 'center'},"row-config":{isHover: true}}},[_c('vxe-colgroup',{attrs:{"title":"","width":"120"}},[_c('vxe-column',{attrs:{"type":"seq","width":"60"}}),_c('vxe-column',{attrs:{"field":"name","title":_vm.showLang('com.site.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}}])})],1),(_vm.hasPowerBox)?_c('vxe-colgroup',{attrs:{"title":_vm.showLang('com.device.box')}},[_c('vxe-column',{attrs:{"field":"online","title":_vm.showLang('com.state.onLine.status'),"class-name":"cell-text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{attrs:{"src":row.online ? _vm.img.light.road.tree.online : _vm.img.light.road.tree.offline}})]}}],null,false,1069910766)}),_c('vxe-column',{attrs:{"field":"alarm","title":_vm.showLang('com.state.police.status'),"class-name":"cell-text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{attrs:{"src":row.alarm ? _vm.img.light.road.tree.alarm : _vm.img.light.road.tree.offline}})]}}],null,false,1604123470)}),_c('vxe-column',{attrs:{"field":"running","title":_vm.showLang('com.state.lighting.status'),"class-name":"cell-text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{attrs:{"src":row.running ? _vm.img.light.road.tree.online : _vm.img.light.road.tree.offline}})]}}],null,false,2598569062)}),_c('vxe-column',{attrs:{"field":"lastTime","title":_vm.showLang('com.ins.time.last'),"width":"155"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.lastTime)+" ")]}}],null,false,1539548064)}),_c('vxe-column',{attrs:{"title":_vm.showLang('com.data.detail'),"class-name":"cell-text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.viewDetail(row, 'power')}}},[_vm._v(_vm._s(_vm.showLang('com.data.detail')))])]}}],null,false,1783656239)})],1):_vm._e(),(_vm.hasLight)?_c('vxe-colgroup',{attrs:{"title":_vm.showLang('com.device.lamp')}},[_c('vxe-column',{attrs:{"title":_vm.showLang('com.lamp.total'),"class-name":"cell-text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.lightCount)+" ")]}}],null,false,1164788834)}),_c('vxe-column',{attrs:{"title":_vm.showLang('com.state.onLine'),"class-name":"cell-text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"color1"},[_vm._v(_vm._s(row.lightOnline))])]}}],null,false,1839254495)}),_c('vxe-column',{attrs:{"title":_vm.showLang('com.right.nav.alarm'),"class-name":"cell-text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"color2"},[_vm._v(_vm._s(row.lightAlarm))])]}}],null,false,168220800)}),_c('vxe-column',{attrs:{"title":_vm.showLang('com.state.lighting'),"class-name":"cell-text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"color3"},[_vm._v(_vm._s(row.lightRunning))])]}}],null,false,1209801045)}),_c('vxe-column',{attrs:{"title":_vm.showLang('com.ins.time.last'),"width":"155"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.lightTime)+" ")]}}],null,false,2413098708)}),_c('vxe-column',{attrs:{"title":_vm.showLang('com.data.detail'),"class-name":"cell-text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.lightCount > 0)?_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.viewDetail(row, 'light')}}},[_vm._v(_vm._s(_vm.showLang('com.data.detail')))]):_c('span',[_vm._v("-")])]}}],null,false,565886819)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }