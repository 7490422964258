<template>
  <div class="station-base-container">
    <div class="device-type-switch" v-if="selectedNode.type == productCodes.station && (hasPowerBox && hasLight)">
      <a href="javascript:void(0);" v-if="hasPowerBox" :class="['device-type-box device-type-left ',stationTabName=='power'?'device-type-success':'']"
        @click="switchTab('power')">{{showLang('com.device.box')}}</a>
      <a href="javascript:void(0);" v-if="hasLight" :class="['device-type-box device-type-rig',stationTabName=='light'?'device-type-success':'']"
        @click="switchTab('light')">{{showLang('com.device.lamp')}}</a>
    </div>
    <BaseGroupTotal v-if="selectedNode.type != productCodes.station" />
    <BaseStationTotal v-else />
    <BaseGroupList v-if="selectedNode.type != productCodes.station" style="margin-top:15px;" />
    <template v-else>
      <PowerControlMenu style="margin-top:15px;" v-if="stationTabName == 'power'" :onlyLeakage="onlyLeakage" @command="doPowerCmd" />
      <LightControlMenu style="margin-top:15px;" v-else @command="doLightCmd" />
      <BaseStationList ref="lightList1" style="margin-top:15px;" />
    </template>
    <ModalControlOutput v-model="showArgModal" :command="cmdItem" @saved="argsSaved" />
    <ModalLightArgs v-model="showLightArgModal" :command="cmdItem" @saved="lightArgsSaved" />
    <ModalCheckAuth v-model="showCheckAuthModal" :users="checkAuthModalUsers" @checked="cmdAuthUserChecked" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import PowerControlMenu from './control/PowerControlMenu'
import LightControlMenu from './control/LightControlMenu'
import ModalControlOutput from './control/ModalControlOutput'
import ModalLightArgs from '@/components/control/light/args/ModalArgEdit'
import BaseGroupTotal from './layout/GroupTotal'
import BaseGroupList from './layout/GroupList'
import BaseStationTotal from './layout/StationTotal'
import BaseStationList from './layout/StationList'
import ModalCheckAuth from '@/views/light/road/monitor/station/control/ModalCheckAuth'
export default {
  name: 'StationBaseIndex',
  components: {
    PowerControlMenu,
    LightControlMenu,
    ModalControlOutput,
    BaseGroupTotal,
    BaseStationTotal,
    BaseGroupList,
    BaseStationList,
    ModalLightArgs,
    ModalCheckAuth,
  },
  props: {
  },
  data() {
    return {
      organMaintenance: {},
      organOwner: {},
      visible: false,
      showArgModal: false,
      showLightArgModal: false,
      showMonitorModal: false,
      cmdType: 'powerbox',
      showCheckAuthModal: false,
      checkAuthModalSuccessFunction: null,
      checkAuthModalUsers: [],
      cmdItem: {},
      lightStyle: 'config', //单灯标签页： config = 组态，list = 列表
      showControlTimeModal: false,
      showViewTimeModal: false,
      modal: 'alarm', //base, ctrl, alarm, analysis,
      orders: [],
      remotes: [],
      pageId: 0,
      deviceCode: '',
      pages: [],
      commandItem: {},
      info: { organs: '', road: '', rule: {} },
      cmdIds: [],
      powerTableActions: {
        0: '关闭',
        1: '开启',
        2: '无效'
      },
      cmdResults: {},
    }
  },
  computed: {
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang']),
    ...mapState("cmd", ["commands", "cmdResultRefresh"]),
    ...mapState('common', ['weeks', 'productCodes', 'deviceTypes', 'monitorActionTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('auth', ['showSpin', 'stationTabName']),
    currentStation: function () {
      if (this.selectedNode.type == this.productCodes.station) {
        return this.selectedNode;
      }
      return null;
    },
    hasPowerBox: function () {
      return this.selectedNode.type == this.productCodes.station && this.selectedNode.hasPower;
    },
    hasLight: function () {
      return this.selectedNode.type == this.productCodes.station && this.selectedNode.hasLight;
    },
    onlyLeakage: function () {
      return this.selectedNode.type == this.productCodes.station && !this.selectedNode.hasControl;
    },
  },
  watch: {
  },
  mounted: function () {
    this.powerTableActions = {
      0: this.showLang('com.op.close'),// '关闭',
      1: this.showLang('com.state.open'),// '开启',
      2: this.showLang('com.state.invalid'),// '无效'
    }
    window.eventBus.$on('paramCommandComing', params => {
      if(this.cmdResults.cmdId != params.commandId)return;
      this.cmdResults.status = params.status;
      this.cmdResults.message = params.message;
      this.cmdResults.content = params.content;

      let contents = [];
      if (this.stationTabName != 'light') {
        contents.push(this.getCmdStatus(this.cmdResults));
      } else {
        this.$refs.lightList1.setCmdContent(this.cmdResults.cmdId, this.cmdResults, this.getCmdStatus(this.cmdResults));
      }
      if (this.stationTabName != 'light') {
        let content = contents.join(' ');
        this.$store.commit('auth/hideSpin');
        setTimeout(() => {
          this.$Modal.success({
            title: this.showLang('com.ins.result'),
            content: content
          });
        }, 500);
      }
    })
  },
  destroyed: function () {
    window.eventBus.$off('paramCommandComing');
    // clearInterval(this.timer);
  },
  methods: {
    getCmdStatus: function (cmd) {
      let p = '<p>', p1 = '</p>';
      if (this.stationTabName == 'light') {
        p = '';
        p1 = ' ';
      }
      let content = `${p}${this.showLang('com.ins.create')}${p1}`;
      if (cmd.status == 2) {
        if (cmd.args.channel) {
          content = `${p}${this.showLang('com.ins.ch.reply', cmd.args.channel)}${p1}`;

        } else {
          content = `${p}${this.showLang('com.ins.dev.reply')}${p1}`
        }
      }
      else if (cmd.status == 8) {
        if (cmd.args.channel) {
          content = `${p}${this.showLang('com.ins.ch')}K${cmd.args.channel}${this.showLang('com.ins.fail')}：${cmd.result}${p1}`;
        } else {
          content = `${p}${this.showLang('com.ins.fail')}：${cmd.result}${p1}`
        }
      } else if (cmd.status == 4) {
        if (cmd.args.channel) {
          content = `${p}${this.showLang('com.ins.ch')}K${cmd.args.channel}${this.showLang('com.ins.execution')}：${cmd.content.pkg} / ${cmd.content.total}${p1}`;
        } else {
          content = `${p}${this.showLang('com.ins.execution')}：${cmd.content.pkg} / ${cmd.content.total}${p1}`
        }
      } else if (cmd.status == 5) {
        if (cmd.args.channel) {
          content = `${p}${this.showLang('com.ins.ch')}K${cmd.args.channel}${this.showLang('com.ins.fail.retry')}：${cmd.result}${p1}`;
        } else {
          content = `${p}${this.showLang('com.ins.fail.retry')}：${cmd.result}${p1}`;
        }
      } else if (cmd.status == 9) {
        if (cmd.code == "getTimeTable") {
          let day = "day" + cmd.args.day;
          let con = cmd.content[day];
          content = `${p}${this.showLang('com.ins.timeTable')}：${cmd.args.month}${this.showLang('com.date.month')}${cmd.args.day}${this.showLang('com.date.sun')}<br> ${con.t1} - ${this.powerTableActions[con.a1]}<br>${con.t2} - ${this.powerTableActions[con.a2]}<br> ${con.t3} - ${this.powerTableActions[con.a3]}<br> ${con.t4} - ${this.powerTableActions[con.a4]}${p1}`;
        } else if (cmd.code == "getclock") {
          content = `${p}${this.showLang('com.ins.dev.currentTime')}: ${cmd.content.nowTime}${p1}`;
        } else if (cmd.code == "setclock") {
          let myDate = new Date();
          content = `${p}${this.showLang('com.but.get.Calibrate')}: ${myDate.format('yyyy-MM-dd HH:mm:ss')}${p1}`;
        } else if (cmd.code == 'getYearPlan') {
          if (cmd.args.month != cmd.content.month) {
            content = `${p}${this.showLang('com.ins.success.WrongMonth', cmd.args.month)}${cmd.content.month}${p1}`;
          } else {
            let strs = [];
            let obj = cmd.content[`day${cmd.args.day}`];
            if (cmd.content.mode == 4) { //北京模式
              for (let i = 1; i <= 4; i++) {
                let ao = this.powerTableActions[obj[`a${i}`]];
                let to = obj[`o${i}`];
                // let ac = this.powerTableActions[obj[`ac${i}`]];
                let tc = obj[`c${i}`];
                strs.push(`${p}${i}、${to}-${tc}:${ao}${p1}`);
              }
            } else if (cmd.content.mode == 5) { //集中器模式
              for (let i = 1; i <= 6; i++) {
                let a = obj[`a${i}`]; //this.monitorActionTypes[];
                let t = obj[`t${i}`];
                let v = this.monitorActionTypes[a]; //a == 1 ? '' : obj[`v${i}`];
                if (a != 1) {
                  v += ` - ${obj[`v${i}`]}`
                }
                strs.push(`${p}${i}、${t} - ${v}${p1}`);
              }
            } else {
              for (let i = 1; i <= 4; i++) {
                let a = this.powerTableActions[obj[`a${i}`]];
                let t = obj[`t${i}`];
                strs.push(`${p}${i}、${t} - ${a}${p1}`);
              }
            }
            content = `${p}${this.showLang('com.ins.ch')}K${cmd.args.channel}${this.showLang('com.ins.success')}：${cmd.args.month}${this.showLang('com.date.month')}${cmd.args.day}${this.showLang('com.date.sun')}${this.showLang('com.date.plan')}：${strs.join(' ')}${p1}`
          }
        } else if (cmd.code == 'getInfo') {
          content = `${p}${this.showLang('com.site.hw')}：${cmd.content.hardware}${p1}${p}${this.showLang('com.site.sw')}：${cmd.content.software}${p1}${p}${this.showLang('com.user.manufacturer')}：${cmd.content.manufacture}${p1}`
        } else if (cmd.code == 'getSimInfo') {
          content = `${p}ICCID: ${cmd.content.iccid}${p1}${p}IMEI: ${cmd.content.imei}${p1}${p}RSSI: ${cmd.content.rssi}${p1}`
        } else if (cmd.code == 'getNetwork') {
          content = `${p}IP:${cmd.content.host}${p1}${p}${this.showLang('com.ins.port')}:${cmd.content.port}${p1}${p}${this.showLang('com.ins.heartbeat')}:${cmd.content.heart}S${p1}`
        } else if (cmd.code == 'getDayPlan1') {
          let str = '';
          for (let n = 0; n < 6; n++) {
            let item = cmd.content[n];
            if (!item) continue;
            str += `${this.showLang('com.date.period')}${n + 1}，${this.showLang('com.date.time')}：${item.min}min，`;
            for (let c = 0; c < 4; c++) {
              let bright = item[`ch${c + 1}`];
              let color = item[`c${c + 1}`];
              if (bright != undefined) {
                str += `${c + 1}${this.showLang('com.ins.ch.light')}：${bright}，`;
              }
              if (color != undefined) {
                str += `${c + 1}${this.showLang('com.ins.ch.color')}：${color}，`;
              }
            }
          }
          content = str;
        } else if (cmd.code == 'getDayPlan2') {
          let str = '';
          for (let n = 0; n < 6; n++) {
            let item = cmd.content[n];
            if (!item) continue;
            str += `${this.showLang('com.date.period')}${n + 1}，${this.showLang('com.date.time')}：${item.min}min，`;
            for (let c = 0; c < 4; c++) {
              let bright = item[`ch${c + 1}`];
              let color = item[`c${c + 1}`];
              if (bright != undefined) {
                str += `${c + 1}${this.showLang('com.ins.ch.light')}：${bright}，`;
              }
              if (color != undefined) {
                str += `${c + 1}${this.showLang('com.ins.ch.color')}：${color}，`;
              }
            }
          }
          content = str;
        } else if (cmd.code == 'getGroup') {
          content = `${p}${this.showLang('com.group.number')}: ${cmd.content.no}${p1}`
        } else if (cmd.code == 'getPlanType') {
          content = `${p}${this.showLang('com.but.tableType')}: ${cmd.content.type == 0 ? this.showLang("com.but.timing") : this.showLang("com.but.ontime")}`
        } else if (cmd.code == 'getAlarmRange') {
          content = `${this.showLang('com.lighting.V.min')}：${cmd.content.vLow}；
                ${this.showLang('com.lighting.V.max')}：${cmd.content.vHigh}；
                ${this.showLang('com.lighting.I.min')}：${cmd.content.cLow}；
                ${this.showLang('com.lighting.I.max')}：${cmd.content.cHigh}；
                ${this.showLang('com.lighting.T.min')}：${cmd.content.tLow}；
                ${this.showLang('com.lighting.T.max')}：${cmd.content.tHigh}；
                ${this.showLang('com.ins.escalation.heartbeat')}：${cmd.content.interval}min；`
        } else if (cmd.code == 'getUnionParam') {
          content = `
              <p>${this.showLang('com.lighting.delay.lamp1')}：${cmd.content.keep1}s</p>
              <p>${this.showLang('com.lighting.delay.lamp2')}：${cmd.content.keep2}s</p>
              <p>${this.showLang('com.lighting.is.sensing')}：${cmd.content.sensor == 1 ? this.showLang('com.state.effective') : this.showLang('com.state.invalid')}；</p>
              <p>${this.showLang('com.lighting.is.signal')}：${cmd.content.txen > 10 ? this.showLang('com.state.effective') : this.showLang('com.state.invalid')}；</p>
              <p>${this.showLang('com.lighting.send.cycle')}：${cmd.content.period}s</p>
              <p>${this.showLang('com.lighting.linkage.number')}：${cmd.content.rfsn}；</p>
              <p>433${this.showLang('com.lighting.send.power')}：${cmd.content.txpower}；</p>
              <p>433${this.showLang('com.lighting.channel')}：${cmd.content.channel}；</p>
              <p>${this.showLang('com.lighting.response.range')}：${cmd.content.range}；</p>
              <p>${this.showLang('com.lighting.is.put.signal')}：${cmd.content.rxen == 1 ? this.showLang('com.state.receive') : this.showLang('com.state.no.receive')}；</p>
              <p>${this.showLang('com.lighting.linkage.mode')}：${cmd.content.mode == 1 ? this.showLang('com.lighting.Linkage.control') : this.showLang('com.lighting.lamp.contact')}；</p>
              <p>${this.showLang('com.lighting.repeat.num')}：${cmd.content.repeat}；</p>
              `
        } else if (cmd.code == 'getAlarmStatus') {
          content = `${this.showLang('com.lighting.V.alarm')}：${cmd.content.voltage ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}
                ${this.showLang('com.lighting.I.alarm')}：${cmd.content.current ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}
                ${this.showLang('com.lighting.T.alarm')}：${cmd.content.temp ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}`
        } else if (cmd.code == 'getGyroLevel') {
          content = `${this.showLang('com.but.tiltThreshold')}：${cmd.content.level}；`
        } else if (cmd.args.channel) {
          content = p + this.showLang('com.ins.ch') + 'K' + cmd.args.channel + this.showLang('com.ins.success') + p1;
        } else {
          content = p + this.showLang('com.ins.success') + p1;
        }
      }
      return content;
    },
    cmdResultChanged: function () {
      let cmds = this.commands.filter(p => this.cmdIds.indexOf(p.id) != -1);
      if (cmds.length == 0) return;
      let contents = [];
      for (let cmd of cmds) {
        if (this.stationTabName != 'light') {
          contents.push(this.getCmdStatus(cmd));
        } else {
          this.$refs.lightList1.setCmdContent(cmd.id, cmd, this.getCmdStatus(cmd));
        }
      }
      if (this.stationTabName != 'light') {
        let content = contents.join(' ');
        this.$store.commit('auth/hideSpin');
        setTimeout(() => {
          this.$Modal.success({
            title: this.showLang('com.ins.result'),
            content: content
          });
        }, 500);
      }
    },
    getBoxDeviceIds: function () {
      if (!this.currentStation) return [];
      return this.currentStation.deviceIds;
    },
    getBoxMainIds: function () {
      if (!this.currentStation) return [];
      return this.currentStation.mainIds;
    },
    lightArgsSaved: function (params) {
      let cmds = [], cmdids = [];
      let chks = this.$refs.lightList1.getSelectedItems();
      let ns = [];
      // let ds = {};
      this.checkCmdAuth(this.cmdType, params.paramsCode, false, (checkResult) => {
        this.showLightArgModal = false;
        for (let chk of chks) {
          ns.push(chk.name);
          // ds[chk.name] = chk.topId;
          chk.result = '';
          chk.status = 0;
          let args = this.clone(params.args);// {channel: chk.channel}
          // args.channel = chk.channel;
          // params.args.channel = chk.channel;
          let code = params.code;
          if (params.code == 'openSingle' || params.code == 'closeSingle') {
            code = 'handSingle';
          } else if (params.code == 'handControl') {
            args.point = false;
            // args.expire = params.args.expire;
            // params.args.point = false;
          } else if (params.code == 'update') {
            args.flag = 1;
          }
          cmdids.push(chk.id);
          cmds.push({ id: chk.id, code: code, name: params.name, deviceId: chk.devId, checkUserId: checkResult.userId, args: args });
        }
        if (params.code == 'update') {
          this.$Modal.confirm({
            title: this.showLang('com.op.prompt'),
            content: this.showLang('com.ins.upgrade.device', ns),
            onOk: async () => {
              this.$set(this, 'cmdIds', cmdids);
              this.$store.dispatch('cmd/sendCommands', cmds);
            },
          });
        } else {
          this.$set(this, 'cmdIds', cmdids);
          this.$store.dispatch('cmd/sendCommands', cmds);
        }
      });
    },
    argsSaved: function (params) {
      let devs = this.getBoxDeviceIds();
      let devId = devs[0];
      let cmds = [], cmdids = [];
      let cmdtype = this.cmdType;
      let cmdCode = params.paramsCode;
      if(params.paramsCode == 'openControl'){
        cmdtype = 'output';
        cmdCode = 'handControl';
      }
      if(params.paramsCode == 'getYearPlan' || params.paramsCode == 'setYearPlan'){
        cmdtype = 'output';
      }
      this.checkCmdAuth(cmdtype, cmdCode, params.args.open == true, (checkResult) => {
        this.showArgModal = false;
        if (params.cmd.type == 'power') {
          if (params.cmd.code == 'openControl') {
            // let idx = 1;
            // let pidx = 0;
            let cmd = {
              code: 'handControl',
              list: [],
              args: {
                enable: true,
                open: params.args.open,
                point: false,
                expire: params.args.expire,
              },
              checkUserId: checkResult.userId,
            };
            for (let ch of params.args.params) {
              cmd.list.push(ch);
              // let cmdid = `openControl${idx++}`;
              // cmdids.push(cmdid);
              // let paramId = params.args.params[pidx++];
              // cmds.push({
              //   id: cmdid, code: 'handControl', name: params.cmd.name, deviceId: devId, checkUserId: checkResult.userId, paramId: paramId, args: {
              //     channel: ch,
              //     enable: true,
              //     open: params.args.open,
              //     point: params.args.locks.indexOf(ch) != -1,
              //     expire: params.args.expire,
              //   }
              // });
            }
            this.sendOutputCommand(cmd);
            return;
          } else if (params.cmd.code == 'getYearPlan') {
            let cmd = {
              code: 'getYearPlan',
              list: [],
              args: {
                month: params.args.month,
                day: params.args.day,
              },
              checkUserId: checkResult.userId,
            };
            // let idx = 1;
            for (let ch of params.args.channels) {
              cmd.list.push(ch);
              // let cmdid = `getYearPlan${idx++}`;
              // cmdids.push(cmdid);
              // cmds.push({
              //   id: cmdid, code: 'getYearPlan', name: params.cmd.name, deviceId: devId, checkUserId: checkResult.userId, args: {
              //     channel: ch,
              //     month: params.args.month,
              //     day: params.args.day,
              //   }
              // });
            }
            this.sendOutputCommand(cmd);
            return;
          } else if (params.cmd.code == 'setYearPlan') {
            let cmd = {
              code: 'setYearPlan',
              list: [],
              args: {
              },
              checkUserId: checkResult.userId,
            };
            // let idx = 1;
            for (let ch of params.args.channels) {
              cmd.list.push(ch);
              // let cmdid = `setYearPlan${idx++}`;
              // cmdids.push(cmdid);
              // cmds.push({
              //   id: cmdid, code: 'setYearPlan', name: params.cmd.name, deviceId: devId, checkUserId: checkResult.userId, args: {
              //     channel: ch,
              //     timeId: params.args.timeIds[idx - 2],
              //   }
              // });
            }
            this.sendOutputCommand(cmd);
            return;
          } else if (params.cmd.code == 'update') {
            let devs = this.getBoxMainIds();
            let cmd = {
              code: 'update',
              list: [devs[0]],
              args: params.args,
              checkUserId: checkResult.userId,
            };
            this.$Modal.confirm({
              title: this.showLang('com.op.prompt'),
              content: this.showLang('com.ins.upgrade.device', this.currentStation.station.name),
              onOk: async () => {
                this.sendPowerboxCommand(cmd);
              },
            });
            return;
            // let idx = 1;
            // let cmdid = `update${idx++}`;
            // cmdids.push(cmdid);
            // cmds.push({ id: cmdid, code: 'update', name: params.cmd.name, deviceId: devId, checkUserId: checkResult.userId, args: params.args });
          } else if (params.cmd.code == 'setNetwork') {
            let idx = 1;
            let cmdid = `setNetwork${idx++}`;
            cmdids.push(cmdid);
            cmds.push({ id: cmdid, code: 'setNetwork', name: params.cmd.name, deviceId: devId, checkUserId: checkResult.userId, args: params.args });
          } else {
            let idx = 1;
            let cmdid = `${params.cmd.code}${idx++}`;
            cmdids.push(cmdid);
            cmds.push({ id: cmdid, code: params.cmd.code, name: params.cmd.name, deviceId: devId, checkUserId: checkResult.userId, args: params.args });
          }
          // if (cmd.list.length == 0) {
          //   this.$Modal.error({
          //     title: this.showLang('com.ins.generate'),
          //     content: this.showLang('com.ins.no.generate'),
          //   });
          //   return;
          // }
          // if (params.cmd.code == 'update') {
            // this.$Modal.confirm({
              // title: this.showLang('com.op.prompt'),
              // content: this.showLang('com.ins.upgrade.device', this.currentStation.station.name),
              // onOk: async () => {
                // this.$set(this, 'cmdIds', cmdids);
                // this.$store.commit('auth/showSpin', { content: this.showLang('com.ins.send.wait.reply'), timeout: 20 });
                // this.$store.dispatch('cmd/sendCommands', cmds).then(res => {
                  // if (res.code != 0) {
                    // this.$Modal.error({
                      // title: this.showLang('com.ins.sending.error'),
                      // content: res.data
                    // });
                  // }
                // });
              // },
            // });
          // } else {
            // this.$set(this, 'cmdIds', cmdids);
            // this.$store.commit('auth/showSpin', { content: this.showLang('com.ins.send.wait.reply'), timeout: 20 });
            // this.$store.dispatch('cmd/sendCommands', cmds).then(res => {
              // if (res.code != 0) {
                // this.$Modal.error({
                  // title: this.showLang('com.ins.sending.error'),
                  // content: res.data
                // });
              // }
            // });
          // }

        } else if (params.cmd.type == 'light') {
          this.$refs.lightList1.doCommand(params);
          //let chks = this.$refs.lightList1.getSelectedItems();
          //let cmds = []
          //let ns = [];
          //for (let chk of chks) {
          //  ns.push(chk.name);
          //  chk.result = '';
          //  chk.status = 0;
          //  let args = this.clone(params.args);
          //  let code = params.cmd.code;
          //  if (params.cmd.code == 'openSingle' || params.cmd.code == 'closeSingle') {
          //    code = 'handSingle';
          //  } else if (params.cmd.code == 'handControl') {
          //    args.point = false;
          //  } else if (params.cmd.code == 'setYearPlan') {
          //    args.timeId = chk.timeId;
          //  } else if (params.cmd.code == 'update') {
          //    args.flag = 1;
          //  }
          //  cmdids.push(chk.id);
          //  cmds.push({ id: chk.id, code: code, name: params.cmd.name, deviceId: chk.devId, checkUserId: checkResult.userId, args: args });
          //}
          //if (params.cmd.code == 'update') {
          //  this.$Modal.confirm({
          //    title: this.showLang('com.op.prompt'),
          //    content: this.showLang('com.ins.upgrade.device', ns),
          //    onOk: async () => {
          //      this.$set(this, 'cmdIds', cmdids);
          //      this.$store.dispatch('cmd/sendCommands', cmds);
          //    },
          //  });
          //} else {
          //  this.$set(this, 'cmdIds', cmdids);
          //  this.$store.dispatch('cmd/sendCommands', cmds);
          //}
        }
      });
    },
    cmdAuthUserChecked: function(params){
      if(this.checkAuthModalSuccessFunction){
        this.checkAuthModalSuccessFunction(params);
      }
    },
    checkCmdAuth: function(type, cmdCode, isOpen, checkOkFun){
      this.$axios.post(`//${this.domains.trans}/sys/setting/CheckCmdAuth`, {type, code: cmdCode, open: isOpen}).then(res => {
        if(res.code != 0)return;
        if(!res.data.need){
          checkOkFun({userId: 0});
          return;
        }
        this.checkAuthModalSuccessFunction = checkOkFun;
        this.checkAuthModalUsers = res.data.users;
        this.showCheckAuthModal = true;
      });
    },
    doLightCmd: function (cmd) {
      this.$refs.lightList1.doCommand(cmd);
      // this.cmdType = 'light';
      // let list = this.$refs.lightList1.getSelectedItems();
      // if (list.length == 0) {
      //   this.$Modal.error({
      //     title: this.showLang('com.ins.arrange.wrong'),
      //     content: this.showLang('com.ins.select.lamp.controller'),
      //   });
      //   return;
      // }
      // if (cmd.needArgs || cmd.needPswd) {
      //   this.cmdItem = { paramsCode: cmd.code, code: cmd.code, name: cmd.name, pswd: cmd.needPswd, args: {} };
      //   this.showLightArgModal = true;
      // } else {
      //   this.checkCmdAuth(this.cmdType, cmd.code, false, (checkResult) => {
      //     this.showLightArgModal = false;
      //     // let cmds = [], cmdIds = [];
      //     let cmd1 = {
      //       code: cmd.code,
      //       list: [],
      //       args: {},
      //       checkUserId: checkResult.userId
      //     }
      //     for (let l of list) {
      //       cmd1.list.push(l.lid);
      //       // if (cmds.filter(p => p.deviceId == l.pid).length > 0) continue;
      //       // l.result = '';
      //       // l.content = {};
      //       // l.status = 0;
      //       // cmdIds.push(l.id);
      //       // cmds.push({ id: l.id, code: cmd.code, name: cmd.name, deviceId: l.devId, checkUserId: checkResult.userId, args: {}, source: "lighting" });
      //     }
      //     this.sendLightCommand(cmd1);
      //     // this.$set(this, 'cmdIds', cmdIds);
      //     // this.$store.dispatch('cmd/sendCommands', cmds).then(res => {
      //     //   if (res.code != 0) {
      //     //     this.$Modal.error({
      //     //       title: this.showLang('com.ins.sending.error'),
      //     //       content: res.data
      //     //     });
      //     //   }
      //     // });
      //   });
      // }
    },
    doPowerCmd: function (cmd) {
      this.cmdType = 'powerbox';
      let devs = this.getBoxMainIds();
      if (devs.length == 0) {
        this.$Modal.error({
          title: this.showLang('com.ins.arrange.wrong'),
          content: this.showLang('com.ins.no.attribute.device'),
        });
        return;
      }
      if (cmd.needArgs || cmd.needPswd) {
        cmd.type = 'power';
        cmd.devs = devs;
        this.cmdItem = cmd;
        this.showArgModal = true;
      } else {
        this.checkCmdAuth(this.cmdType, cmd.code, false, (checkResult) => {
          this.showArgModal = false;
          let cmd1 = {
            code: cmd.code,
            list: [devs[0]],
            args: {},
            checkUserId: checkResult.userId,
          }
          this.sendPowerboxCommand(cmd1);
          // let cmds = [{ id: devs[0], code: cmd.code, name: cmd.name, deviceId: devs[0], checkUserId: checkResult.userId, args: {}, source: "lighting" }];
          // this.$set(this, 'cmdIds', [devs[0]]);
          // this.$store.commit('auth/showSpin', { content: this.showLang('com.ins.send.wait.reply'), timeout: 20 });
          // this.$store.dispatch('cmd/sendCommands', cmds).then(res => {
          //   if (res.code != 0) {
          //     this.$Modal.error({
          //       title: this.showLang('com.ins.sending.error'),
          //       content: res.data
          //     });
          //   }
          // });
        });
      }
    },
    sendPowerboxCommand: function(cmd){
      if(cmd.list.length == 0)return;
      this.$store.commit('auth/showSpin', { content: this.showLang('com.ins.send.wait.reply'), timeout: 20 });
      this.$set(this, 'cmdResults', {});
      this.$axios.post(`//${this.domains.trans}/station/command/SendPowerboxOld`, cmd).then(res => {
        if (res.code == 0) {
          for (let item of res.data.list) {
            // let ds = this.devices.filter(p => p.id == item.id);
            // if (ds.length == 0) continue;
            this.$set(this.cmdResults, 'code', cmd.code);
            this.$set(this.cmdResults, 'args', cmd.args);
            this.$set(this.cmdResults, 'cmdId', item.success ? item.message : '');
            this.$set(this.cmdResults, 'status', item.success ? 1 : 8);
            this.$set(this.cmdResults, 'content', {});
            this.$set(this.cmdResults, 'message', item.success ? '指令已进入发送队列' : item.message);
            if(!item.success){
              this.$store.commit('auth/hideSpin');
              setTimeout(() => {
                this.$Modal.success({
                  title: this.showLang('com.ins.result'),
                  content: item.message
                });
              }, 500);
            }
            // this.spanData();
          }
        }
      });
    },
    sendOutputCommand: function(cmd){
      if(cmd.list.length == 0)return;
      this.$store.commit('auth/showSpin', { content: this.showLang('com.ins.send.wait.reply'), timeout: 20 });
      this.$set(this, 'cmdResults', {});
      this.$axios.post(`//${this.domains.trans}/station/command/SendOutputOld`, cmd).then(res => {
        if (res.code == 0) {
          for (let item of res.data.list) {
            // let ds = this.devices.filter(p => p.id == item.id);
            // if (ds.length == 0) continue;
            this.$set(this.cmdResults, 'code', cmd.code);
            this.$set(this.cmdResults, 'args', cmd.args);
            this.$set(this.cmdResults, 'cmdId', item.success ? item.message : '');
            this.$set(this.cmdResults, 'status', item.success ? 1 : 8);
            this.$set(this.cmdResults, 'content', {});
            this.$set(this.cmdResults, 'message', item.success ? '指令已进入发送队列' : item.message);
            if(!item.success){
              this.$store.commit('auth/hideSpin');
              setTimeout(() => {
                this.$Modal.success({
                  title: this.showLang('com.ins.result'),
                  content: item.message
                });
              }, 500);
            }
            // this.spanData();
          }
        }
      });
    },
    switchTab: function (name) {
      this.$store.commit('auth/switchStationTabName', name);
    },
    switchStyle: function (name) {
      this.lightStyle = name;
    },
    close() {
      this.visible = false;
    },
  }
}
</script>
<style scoped>
.station-base-container {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  /* display: flex;
  flex-direction: column;
  align-content: stretch; */
  /* border: solid 1px red; */
  /* height: 500px; */
  background-color: #e3e9ef;
}
.device-type-switch {
  position: fixed;
  top: 110px;
  right: 40px;
  z-index: 15;
  /* width: 100px;
  height: 100px; */
}
.device-type-box {
  display: inline-block;
  width: 70px;
  height: 28px;
  background: transparent;
  border: 1px solid #ffffff;
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.device-type-box.device-type-success {
  color: #2965fb;
  background: #ffffff;
}
.device-type-left {
  border-radius: 14px 0px 0px 14px;
}
.device-type-rig {
  border-radius: 0px 14px 14px 0px;
}
.box-content {
  position: absolute;
  top: 110px;
  bottom: 10px;
  right: 0;
  left: 310px;
  background-color: #e3e9ef;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 300;
  /* border: solid 1px red; */
  overflow: hidden;
}
.box-body {
  height: 0;
  flex: auto;
  margin-top: 5px;
  /* border: solid 1px red; */
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
}
.style-content {
  flex: none;
  display: flex;
  padding: 0 195px 0 30px;
  margin-bottom: 20px;
}
.box-button-list {
  position: absolute;
  right: 0;
  top: 130px;
  width: 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  background: #ffffff;
  box-shadow: 0px 4px 40px 0px rgba(56, 128, 252, 0.5);
  border-radius: 12px 0px 0px 12px;
}
.box-button-item {
  /* margin-top: 8px; */
  flex: none;
  width: 37px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.box-button-item img {
  width: 14px;
  height: 14px;
}
.box-button-item span {
  width: 18px;
  margin: 3px 0 0 6px;
  line-height: 14px;
  font-size: 14px;
  color: #324252;
  font-weight: 400;
}
.box-button-active span {
  color: #3880fc;
}

/* 站点信息 */
.box-header2 {
  flex: none;
  height: 172px;
  background: #fff;
  margin-top: 16px;
  position: relative;
  padding: 25px 30px;
}
.box-header2-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021629;
  border-bottom: 1px solid #dee5ed;
  padding-bottom: 15px;
}
.header-info-region {
  float: right;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  margin-right: 35px;
}
.header-info {
  color: #324252;
  display: flex;
  justify-content: space-evenly;
  padding: 18px 0 5px;
}
.header-info-item {
  flex: auto;
  text-align: left;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
}
.header-info-item img {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 3px;
  margin-top: -3px;
}
.header-info-region img {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 3px;
  margin-top: -3px;
}
.header-simcard {
  height: 20px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff99;
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
}
.header-info-moreBox .simcard-item {
  margin-top: 10px;
}
.simcard-item {
  border-radius: 6px;
  line-height: 12px;
  padding: 2px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
}
.simcard-item img {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-left: -2px;
  margin-top: -3px;
}
</style>