var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-container"},[_c('vxe-table',{key:_vm.dataRefresh,ref:"chkTable",attrs:{"border":"","resizable":"","show-overflow":"","show-header-overflow":"","keep-source":"","data":_vm.showList,"height":_vm.tabHeight,"header-row-class-name":_vm.setHeaderRowClass,"align":"center","header-row-style":{textAlign: 'center'},"row-config":{isHover: true}}},[_c('vxe-colgroup',{attrs:{"title":"","width":"120"}},[_c('vxe-column',{attrs:{"type":"seq","width":"60"}}),_c('vxe-column',{attrs:{"field":"name","title":_vm.showLang('com.site.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}}])})],1),_c('vxe-colgroup',{attrs:{"title":_vm.showLang('com.device.box')}},[_c('vxe-column',{attrs:{"field":"online","title":_vm.showLang('com.state.onLine.status'),"class-name":"cell-text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{attrs:{"src":row.online ? _vm.img.light.road.tree.online : _vm.img.light.road.tree.offline}})]}}])}),_c('vxe-column',{attrs:{"field":"alarm","title":_vm.showLang('com.state.police.status'),"class-name":"cell-text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{attrs:{"src":row.alarm ? _vm.img.light.road.tree.alarm : _vm.img.light.road.tree.offline}})]}}])}),_c('vxe-column',{attrs:{"field":"running","title":_vm.showLang('com.state.lighting.status'),"class-name":"cell-text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{attrs:{"src":row.running ? _vm.img.light.road.tree.online : _vm.img.light.road.tree.offline}})]}}])})],1),_c('vxe-colgroup',{attrs:{"title":_vm.showLang('com.device.lamp')}},[_c('vxe-column',{attrs:{"title":_vm.showLang('com.lamp.total'),"class-name":"cell-text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.lightCount)+" ")]}}])}),_c('vxe-column',{attrs:{"title":_vm.showLang('com.right.nav.alarm'),"class-name":"cell-text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"color2"},[_vm._v(_vm._s(row.lightAlarm))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }